import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import { ContentFull } from "../elements/Content"
import { H3 } from "../elements/Text"
import LinkTo from "../components/LinkTo"

const CaseStudyContent = styled(ContentFull)`
  ${tw`bg-white text-center`}
`

const ContentTitle = styled(H3)`
  ${tw`w-full text-center`}
`

export default function CaseStudies() {
  return (
    <CaseStudyContent>
      <ContentTitle>
        see how we built an industry leading API at{" "}
        <LinkTo href="/case-studies/ttc/">The Travel Corporation</LinkTo>
      </ContentTitle>
    </CaseStudyContent>
  )
}
