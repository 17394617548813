import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Inner } from "../elements/Content"
import { P, Strong } from "../elements/Text"
import FLogo from "../images/logo.svg"
import FooterContent from "./FooterContent"
import blueBlockStyle from "../styles/blueBlock"

const ContentWrapper = styled.div`
  ${tw`bg-grey-lightest pt-16 w-full`};
  ${blueBlockStyle}
`

const Logo = styled(FLogo)`
  ${tw`h-48 w-48 mr-2 text-white p-2 bg-transparent`};
`

const Wrapper = styled.div`
  ${tw`lg:flex lg:justify-center lg:mx-auto`};
`
const Details = styled.div`
  ${tw`lg:w-1/3 text-center`};
`

const WhiteP = styled(P)`
  ${tw`text-white`}
`

const Contact = styled(P)`
  ${tw`lg:flex lg:justify-center lg:mx-auto relative mt-2 leading-tight`};
`

const ContactContent = () => (
  <>
    <ContentWrapper id="contact">
      <Inner>
        <Wrapper>
          <Logo />
        </Wrapper>
        <Contact>
          <a href="mailto:hello@featurist.co.uk">hello@featurist.co.uk</a>
        </Contact>
        <Contact>
          <a href="tel:+442071128708">
            <Strong>0207 112 8708</Strong>
          </a>
        </Contact>
        <Wrapper>
          <Details>
            <WhiteP>
              <Strong>London Office</Strong>
              <br />
              5-7 Tanner St
              <br />
              London
              <br />
              SE1 3LE
            </WhiteP>
          </Details>
          <Details>
            <WhiteP>
              <Strong>Lake Geneva Office</Strong>
              <br />
              Le Meltingspot,
              <br />
              8 avenue du Général de Gaulle,
              <br />
              74200 Thonon-les-Bains
            </WhiteP>
          </Details>
        </Wrapper>
      </Inner>
      <FooterContent />
    </ContentWrapper>
  </>
)

export default ContactContent
