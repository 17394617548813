/* eslint-disable react/no-unescaped-entities */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import { Inner } from "../elements/Content"
import { Title, Highlight, P } from "../elements/Text"
import ValuesGraphicOne from "../images/vg1.svg"
import ValuesGraphicTwo from "../images/vg2.svg"
import ValuesGraphicThree from "../images/vg3.svg"

const ContentWrapper = styled(Inner)`
  ${tw`bg-white lg:flex lg:flex-wrap py-12 mt-bigwhitespace mb-bigwhitespace`};
`

const ContentTitle = styled(Title)`
  ${tw`w-full text-center pb-8 mb-12`}
`

const Slide = styled.div`
  ${tw`pb-12 lg:w-1/3 lg:pb-0 text-center`};
`

const SlideInner = styled.div`
  ${tw``};
`

const SlideMain = styled.div`
  ${tw`px-8`};
`
const SlideText = styled(P)`
  ${Highlight} {
    color: inherit;
    font-weight: bold;
  }
`

const SlideGraphic = styled.div`
  ${tw`mx-auto text-grey-darkest flex flex-col justify-center`};
  height: 250px;
`

const ServicesContent = () => {
  return (
    <>
      <ContentWrapper id="services">
        <ContentTitle>
          Software developers who <Highlight>share your goals</Highlight>
        </ContentTitle>
        <Slide>
          <SlideInner>
            <SlideGraphic>
              <ValuesGraphicOne />
            </SlideGraphic>
            <SlideMain>
              <SlideText>
                Featurist provide pre-formed development teams who work closely
                with your business to build your{" "}
                <Highlight>next big product</Highlight>.
              </SlideText>
            </SlideMain>
          </SlideInner>
        </Slide>
        <Slide>
          <SlideInner>
            <SlideGraphic>
              <ValuesGraphicTwo />
            </SlideGraphic>

            <SlideMain>
              <SlideText>
                Featurist are specialists in <Highlight>successful</Highlight>{" "}
                automated testing, continuous integration and continuous
                delivery.
              </SlideText>
            </SlideMain>
          </SlideInner>
        </Slide>
        <Slide>
          <SlideInner>
            <SlideGraphic>
              <ValuesGraphicThree />
            </SlideGraphic>

            <SlideMain>
              <SlideText>
                Featurist provide pragmatic, <Highlight>experienced</Highlight>{" "}
                technical architects and team leads for large and ambitious
                projects.
              </SlideText>
            </SlideMain>
          </SlideInner>
        </Slide>
      </ContentWrapper>
    </>
  )
}

export default ServicesContent
