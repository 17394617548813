/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, navigate } from "gatsby"
import tw from "tailwind.macro"
import styled, { css } from "styled-components"
import SwipeableViews from "react-swipeable-views"
import { ContentFull, Container, Inner } from "../elements/Content"
import {
  H1Light,
  PStyle,
  textStyleBase,
  textStyleLarge,
} from "../elements/Text"
import IconQuote from "../images/icons/quote.svg"
import LinkTo from "../components/LinkTo"
import Button from "../elements/Button"
import Pager from "../components/Pager"
import CircleIcon from "../elements/CircleIcon"
import blueBlockStyle from "../styles/blueBlock"

const ContentWrapper = styled(ContentFull)`
  ${blueBlockStyle}
  ${tw`lg:pb-16 lg:pt-16`}
`

const Slide = styled.div`
  ${tw`flex-no-shrink p-8`};
`

const SlideInner = styled(Inner)`
  ${tw`lg:flex lg:flex-wrap`};
`

const SlideContent = css`
  ${tw`w-full`};
`

const SlideTitle = styled(H1Light)`
  ${SlideContent};
`

const SlideText = styled.p`
  ${SlideContent};
  ${props => (props.smallText ? textStyleBase : textStyleLarge)}
  ${tw`text-white`};
`

const SlideFooter = styled.div`
  ${PStyle};
  ${SlideContent};
  ${tw`text-grey-lightest md:flex items-center justify-between mt-6`};
`

const SlideCTA = styled(Button)`
  ${tw`text-white mt-8 md:mt-0`};
`

const IconWrapper = styled.span`
  ${tw`inline-block`};
`
const IconContainer = styled.span`
  ${tw`rounded-full bg-pink text-white`};
  ${tw`w-8 h-8 md:w-10 md:h-10 mr-2`};
  ${tw`flex items-center justify-center`};
`

const Quote = styled(IconQuote)`
  width: 60%;
  height: 60%;
`

let autoScroll = false

const ContentContainer = styled(Container)`
  ${tw`text-center p-10 w-full max-w-4xl mx-auto flex flex-flow items-center`}
`

const StyledNavLink = styled.div`
  ${tw`text-center p-5 md:p-6`};
  transition: color 300ms;
  ${({ enabled }) =>
    enabled ? tw`cursor-pointer text-pink` : tw`color-transparent`}}
`

function NavLink({ enabled, onClick, children, ...rest }) {
  return (
    <StyledNavLink
      onClick={e => (enabled ? onClick(e) : undefined)}
      enabled={enabled}
      {...rest}
    >
      {children}
    </StyledNavLink>
  )
}

NavLink.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

NavLink.defaultProps = {
  enabled: true,
  onClick: undefined,
  children: undefined,
}

const LargeNavLink = styled(NavLink)`
  ${tw`hidden xl:block`}
`

const SmallNavLink = styled(NavLink)`
  ${tw`block xl:hidden`}
`

const FullWidthPager = styled(Pager)`
  ${tw`w-full`}
`

const PagerContainer = styled.div`
  ${tw`flex flex-row items-center w-full`}
`

const TestimonialList = data => {
  const items = data.data.allTestimonialsJson.edges
  const itemsCount = items.length

  const [current, setCurrent] = useState(0)
  const changeTo = index => {
    if (typeof window !== "undefined") {
      setCurrent(index)
      // const destinationElementTop = document.querySelector("#testimonials").offsetTop;
      // window.scrollTo({ behavior: "smooth", top: destinationElementTop });
    } else {
      navigate("#testimonials")
    }
  }
  React.useEffect(() => {
    let timer
    if (autoScroll) {
      timer = setTimeout(() => {
        changeTo((current + 1) % itemsCount)
      }, 7000)
    }
    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      <ContentContainer>
        <LargeNavLink
          onClick={() => changeTo(current - 1)}
          enabled={current > 0}
        >
          <CircleIcon fill="transparent" icon="chevronLeft" size={140} />
        </LargeNavLink>
        <SwipeableViews
          enableMouseEvents
          animateHeight={false}
          index={current}
          onChangeIndex={index => {
            autoScroll = false
            changeTo(index)
          }}
        >
          {items.map(item => (
            <Slide key={item.node.title}>
              <SlideInner>
                <SlideTitle>{item.node.title}</SlideTitle>
                <SlideText smallText={item.node.smallText}>
                  <IconWrapper>
                    <IconContainer>
                      <Quote />
                    </IconContainer>
                  </IconWrapper>
                  {item.node.content}
                </SlideText>
                <SlideFooter>
                  <div>
                    <a href={item.node.author.link}>{item.node.author.name}</a>
                    <br />
                    {item.node.author.role}
                  </div>
                  <div>
                    <LinkTo href={item.node.slug}>
                      <SlideCTA>View Case Study &raquo;</SlideCTA>
                    </LinkTo>
                  </div>
                </SlideFooter>
              </SlideInner>
            </Slide>
          ))}
        </SwipeableViews>
        <LargeNavLink
          onClick={() => changeTo(current + 1)}
          enabled={current < items.length - 1}
        >
          <CircleIcon
            style={{ transform: "rotate(180deg)" }}
            fill="transparent"
            icon="chevronLeft"
            size={140}
          />
        </LargeNavLink>
      </ContentContainer>
      <PagerContainer>
        <SmallNavLink
          onClick={() => changeTo(current - 1)}
          enabled={current > 0}
        >
          <CircleIcon fill="transparent" icon="chevronLeft" size={72} />
        </SmallNavLink>
        <FullWidthPager
          tabIndex={current}
          setTabIndex={changeTo}
          tabCount={items.length}
        />
        <SmallNavLink
          onClick={() => changeTo(current + 1)}
          enabled={current < items.length - 1}
        >
          <CircleIcon
            style={{ transform: "rotate(180deg)" }}
            fill="transparent"
            icon="chevronLeft"
            size={72}
          />
        </SmallNavLink>
      </PagerContainer>
    </>
  )
}

const TestimonialsContent = () => (
  <StaticQuery
    query={graphql`
      query testimonialsQuery {
        allTestimonialsJson {
          edges {
            node {
              title
              content
              author {
                name
                role
                link
              }
              slug
              smallText
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <ContentWrapper id="testimonials">
          <TestimonialList data={data} />
        </ContentWrapper>
      </>
    )}
  />
)

export default TestimonialsContent
