import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import Content, { Inner } from "../elements/Content"
import ClientImages from "../components/ClientImages"

const OverlappingContent = styled(Content)`
  ${tw`bg-grey-light`};
`

const ClientsContent = () => (
  <>
    <OverlappingContent>
      <Inner>
        <ClientImages />
      </Inner>
    </OverlappingContent>
  </>
)

export default ClientsContent
