import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Inner } from "../elements/Content"
import { H4 } from "../elements/Text"

const Tabs = styled(Inner)`
  ${tw`flex justify-center lg:m-auto`}
`

const TabsItemsLink = styled(H4)`
  ${tw`flex-none rounded-full hover:opacity-100 bg-pink cursor-pointer`};
  ${tw`w-5 h-5 md:w-6 md:h-6 mx-2 md:mx-3`};
  ${({ active }) => (active ? tw`opacity-100` : tw`opacity-50`)}
  transition: opacity 300ms;
`

function Pager({ tabIndex, setTabIndex, tabCount }) {
  const isActive = index => tabIndex === index

  return (
    <Tabs>
      {[...Array(tabCount).keys()].map(i => (
        <TabsItemsLink
          key={i}
          onClick={() => {
            setTabIndex(i)
          }}
          active={isActive(i)}
        />
      ))}
    </Tabs>
  )
}

Pager.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  tabCount: PropTypes.number.isRequired,
}

export default Pager
