/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import tw from "tailwind.macro"
import LinkTo from "./LinkTo"

export const processImage = graphql`
  fragment processImage on File {
    childImageSharp {
      fluid(maxHeight: 60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const ImageList = styled.div`
  ${tw`flex flex-wrap items-center justify-center`};
  margin-left: -30px;
  margin-right: -30px;
`

const Image = styled(Img)`
  filter: grayscale(85%);
  opacity: 0.65;
`

const ImageWrapper = styled.div`
  ${tw`my-8 mx-6`};
  width: 75px;

  @media only screen and (min-width: 901px) {
    width: 100px;
  }

  ${props =>
    !props.disableHover &&
    `
      &:hover {
        ${Image} {
          filter: none;
          opacity: 1;
        }
      }`}
`

const LogoImage = ({ children, ...props }) => {
  const { node, key, disableHover } = props

  return (
    <ImageWrapper key={key} disableHover={disableHover}>
      <Image
        key={`image-for-${key}`}
        alt={node.frontmatter.title}
        fluid={node.frontmatter.logoImage.childImageSharp.fluid}
        style={{ maxHeight: 60 }}
        imgStyle={{ objectFit: "contain" }}
      />
    </ImageWrapper>
  )
}

const ClientImages = () => (
  <StaticQuery
    query={graphql`
      query ListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { featured: { eq: true } } }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM Do YYYY")
                title
                hidden
                logoImage {
                  childImageSharp {
                    fluid(maxHeight: 60) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <ImageList>
          {data.allMarkdownRemark.edges.map(({ node }, i) => {
            return node.frontmatter.hidden ? (
              <LogoImage node={node} key={`case-study-${i}`} disableHover />
            ) : (
              <LinkTo key={`case-study-${i}`} href={node.fields.slug}>
                <LogoImage node={node} key={`logo-${i}`} />
              </LinkTo>
            )
          })}
        </ImageList>
      )
    }}
  />
)
export default ClientImages
