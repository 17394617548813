import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import ReactRotatingText from "react-rotating-text"
import Layout from "../components/layout"
import { Title, Highlight, H2, H1 } from "../elements/Text"
import SEO from "../components/seo"
import AppWrapper from "../views/AppWrapper"
import TestimonialsContent from "../views/TestimonialsContent"
import ContactContent from "../views/ContactContent"
import ServicesContent from "../views/ServicesContent"
import SkillsContent from "../views/SkillsContent"
import ClientsContent from "../views/ClientsContent"
import Hero from "../views/Hero"
import CaseStudy from "../views/CaseStudy"

require("react-rotating-text/example/src/ReactRotatingText.css")

const HeroTitle = styled(Title)`
  ${tw`text-left`}
`

const MinWidthRotatingText = styled(ReactRotatingText)`
  display: inline-block;
  min-width: 240px;
`

const IndexPage = () => (
  <Layout footer={false}>
    <SEO
      title="Featurist"
      keywords={["Full stack", "development teams", "react", "andoid", "ios"]}
    />
    <AppWrapper>
      <Hero isIndex>
        <HeroTitle>
          <H1>
            Let&apos;s build software that makes a{" "}
            <Highlight>difference</Highlight>
          </H1>
          <H2>
            Featurist is a software development
            <br />
            consultancy that understands{" "}
            <Highlight>
              <MinWidthRotatingText
                items={[
                  "your business",
                  "web development",
                  "your challenges",
                  "continuous delivery",
                  "mobile development",
                  "your customers",
                  "continuous improvement",
                  "your ambitions",
                ]}
              />
            </Highlight>
          </H2>
        </HeroTitle>
      </Hero>
      <ServicesContent />
      <CaseStudy />
      <ClientsContent />
      <TestimonialsContent />
      <SkillsContent />
      <ContactContent />
    </AppWrapper>
  </Layout>
)

export default IndexPage
