/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import { ContentFull } from "../elements/Content"
import { P, Highlight, H1 } from "../elements/Text"
import IconNodeJs from "../images/icons/node-js.svg"
import IconReact from "../images/icons/icon-react.svg"
import IconRails from "../images/icons/rails.svg"
import IconCucumber from "../images/icons/cucumber.svg"
import IconKubernetes from "../images/icons/kubernetes.svg"
import IconTensorflow from "../images/icons/tensorflow.svg"

const ContentWrapper = styled(ContentFull)`
  ${tw`bg-white m-auto mt-bigwhitespace mb-bigwhitespace w-4/5 max-w-3xl lg:w-full xl:w-4/5`};
`

const TechListItemIcon = styled.div`
  ${tw`relative inline-block rounded-full bg-grey-darker m-3`};
  ${tw`flex items-center justify-center text-center text-white`};
  ${tw`text-xs text-white uppercase`};
  ${tw`md:w-24 md:h-24 w-16 h-16 flex-no-grow flex-no-shrink items-center`};
`

const SkillsContainer = styled.div`
  ${tw`flex flex-wrap justify-center`}
`

const Skill = styled.div`
  ${tw`flex flex-col justify-start m-8 items-center w-full lg:w-2/5`}
`

const SkillDescription = styled(P)`
  ${tw`text-justify`}
`

const ContentTitle = styled(H1)`
  ${tw`mb-10`}
`

const SkillContent = () => {
  return (
    <>
      <ContentWrapper id="skills">
        <ContentTitle>
          What we use to <Highlight>get things done</Highlight>
        </ContentTitle>
        <SkillsContainer>
          <Skill>
            <TechListItemIcon>
              <IconNodeJs width="60%" height="60%" />
            </TechListItemIcon>
            <SkillDescription>
              We love <Highlight>Node.js</Highlight>, not just because it has
              excellent performance and flexibility as a platform, but also
              because having <Highlight>JavaScript</Highlight> on the frontend
              and backend can simplify everything.
            </SkillDescription>
          </Skill>
          <Skill>
            <TechListItemIcon>
              <IconRails width="70%" height="70%" />
            </TechListItemIcon>
            <SkillDescription>
              We're always delighted at how productive we are with{" "}
              <Highlight>Ruby on Rails</Highlight>. It's beautiful, there are no
              surprises and it's still a huge player on the web.
            </SkillDescription>
          </Skill>
          <Skill>
            <TechListItemIcon>
              <IconKubernetes width="60%" height="60%" />
            </TechListItemIcon>
            <SkillDescription>
              For hosting our applications, we're still huge fans of{" "}
              <Highlight>Heroku</Highlight> for its simplicity but we love{" "}
              <Highlight>Kubernetes</Highlight> for its flexibility and
              reliability in our more complex deployment environments.
            </SkillDescription>
          </Skill>
          <Skill>
            <TechListItemIcon>
              <IconReact width="60%" height="60%" />
            </TechListItemIcon>
            <SkillDescription>
              We've really enjoyed the huge transformations on the frontend:{" "}
              <Highlight>React</Highlight>, <Highlight>ReactNative</Highlight>,
              a wonderful community - we're far more productive and our users
              notice the results.
            </SkillDescription>
          </Skill>
          <Skill>
            <TechListItemIcon>
              <IconCucumber width="80%" height="80%" />
            </TechListItemIcon>
            <SkillDescription>
              <Highlight>Automated testing</Highlight> has been a cornerstone of
              Featurist's ability to release new features reliably and
              frequently, especially on large and challenging projects.
            </SkillDescription>
          </Skill>
          <Skill>
            <TechListItemIcon>
              <IconTensorflow width="60%" height="60%" />
            </TechListItemIcon>
            <SkillDescription>
              We're always amazed at how <Highlight>Machine Learning</Highlight>{" "}
              has helped us solve problems we previously thought impossible.
              It's going to change things in a big way.
            </SkillDescription>
          </Skill>
        </SkillsContainer>
      </ContentWrapper>
    </>
  )
}

export default SkillContent
